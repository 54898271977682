.select_drop_dwn {
  border-radius: 9px;
  border: 1px solid #bfbfbf;
  background: #fff;
  width: 160px;
  height: 40px;
}

.cluster_table_container table thead tr {
  background: rgba(157, 45, 32, 1);
  box-shadow: 0px 10px 25px 0px rgba(33, 52, 48, 0.08);
  padding-top: 2rem;
}

.cluster_table_container table thead tr th {
  border-radius: 45px 0px 0px 0px;
  /* Only the first th will have rounded corners */
  padding-top: 20px;
  padding-bottom: 20px;
}

.cluster_table_container .table_list_heading {
  color: #fff;
  font-family: "Paris2024-Variable";
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 760;
  line-height: normal;
}

/* .cluster_table_container .cluster_listing_Data td {
    background: #FFF;
    border-radius: 30px;
   
    padding: 30px; 
} */

.cluster_table_container .cluster_listing_Data {
  border-radius: 20px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.cluster_table_container .cluster_listing_Data:first-child td:first-child {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.cluster_table_container .cluster_listing_Data td:last-child {
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
}

.cluster_table_container .cluster_listing_Data td {
  background-color: inherit;
  /* Ensure td inherits the background */
  padding: 10px;
  border: none;

  color: #212121;

  font-family: "Paris2024-Variable";
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  vertical-align: middle;
}

/* .cluster_sport_img {
  height: 40px;
} */

.table-with-spacing {
  border-collapse: separate;
  border-spacing: 0 5px;
  /* 15px space between rows */
}

.daily_table_heading {
  color: #212121;
  font-family: "Paris2024-Variable";
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  /* 120% */
}

.daily_table_para {
  color: #212121;
  font-family: "Paris2024-Variable";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.daily_view_btn {
  border-radius: 15px;
  border: 1px solid #9d2d20;
  background: #fff;
  color: #9d2d20;
  font-size: 1rem;
  font-family: "Paris2024-Variable";
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  /* 140% */
  text-align: center;
  padding: 5px;
}

.calender_wrppaer {
  list-style: none;
  /* margin-top: 3rem; */
  /* padding: none;
    margin: none; */
}


/* @media (max-width: 770px)  {
  .calender_wrppaer {
    margin-top: 20rem;
  }
}  */

.calender_wrppaer h5 {
  color: #000;
  font-family: "Paris2024-Variable";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 5px;
  letter-spacing: 0.12px;
}

.calender_wrppaer p {
  color: #000;
  font-family: "Paris2024-Variable";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */
  letter-spacing: 0.08px;
  text-transform: uppercase;
  margin-top: 15px;
}

.calender_wrppaer li {
  display: flex;
  justify-content: center;
  align-items: center;
}

.calender_active {
  width: 57px;
  height: 95px;
  flex-shrink: 0;
  background-color: var(--heading-color);
  border-radius: 60px;
  /* background: linear-gradient(90deg, #19417a 0%, #0b9444 100%); */
  color: #fff;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.12px;
  cursor: pointer;
}

.calender_active h5 {
  color: #fff;
}

.calender_active p {
  color: #fff;
}

.calender_margin_top {
  /* margin-top: 115px; */
}

.shedule_active_menu_container {
  border-radius: 60px;
  background: #f5eae9;
  padding: 13px 40px;
  list-style: none;
  border:1px solid #ce968f
}

.schedule_sport_name{
  margin-bottom: 5px;
  font-size: 1.1rem;
  text-align: center;
  font-family:"Paris2024-Variable" ;
  white-space: nowrap;
  font-weight: 500;

}


.schudle_active.menu {
  background-color: #0b9444;
  /* Highlight background for active state */
  color: #fff;
  /* Text color for active state */
  font-weight: bold;
  border-radius: 8px;
}

.overiew_margin_top {
  margin-top: 12.1rem;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-bottom: 40px;
  border-radius: 5px;
}

.schedule__menu_title {
  color: #212121;
  font-family: "Paris2024-Variable";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  list-style: none;
  cursor: pointer;
}

.schedule_active_menu {
  border-radius: 60px;
  background-color: var(--heading-color);
  /* background: linear-gradient(90deg, #19417A 0%, #0B9444 100%); */
  color: #fff;
  font-family: "Paris2024-Variable";
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  padding: 10px 40px;
}

@media (min-width: 500px) and (max-width: 599px) {
  .schedule_active_menu {
    font-size: 1rem !important;
    padding: 17px 35px !important;
  }
  .sport_cube_col{
    margin-left:-10px
  }
}
@media (min-width: 425px) and (max-width: 499px) {
  .schedule_active_menu {
    font-size: 1rem !important;
    padding: 15px 30px !important;
  }

}
@media (min-width: 320px) and (max-width: 424px) {
  .schedule_active_menu {
    font-size: 1rem !important;
    padding: 10px 15px !important;
  }
}


.overiew_calender_container h3 {
  color: #000;
  font-family: "Paris2024-Variable";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 5px;
  letter-spacing: 0.12px;
}

.overiew_calender_container p {
  color: #000;
  font-family: "Paris2024-Variable";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.08px;
  text-transform: uppercase;
  margin-top: 15px;
}

.list_style_none {
  list-style: none;
}

.month_wrapper_container h4 {
  color: #000;

  font-family: "Paris2024-Variable";
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 83.333% */
  letter-spacing: 0.12px;
}

.sprots_Discipline_title {
  color: black;
  font-family: "Paris2024-Variable";
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 46px;
  /* 153.333% */
}

.center_title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.schedule-info-container {
  color: white;
  /* Text color */
  padding: 5px 0;
  /* Padding for top and bottom */
}

.info-box h3 {
  font-size: 24px;
  margin: 0;
}

.info-box p {
  margin: 0;
  font-size: 16px;
}

.divider {
  width: 1px;
  height: 50px;
  background-color: white;
}

@media only screen and (max-width: 1366px) {
  .schedule__menu_title {
    font-size: 1.5rem;
    padding: 5px 30px;
  }
}

@media (max-width: 768px) {
  .divider {
    display: none;
  }
  .schedule__menu_title {
    font-size: 1rem;
    padding: 5px 30px;
  }

}

@media only screen and (max-width: 600px) {
  .evolution_uttrakhand_img {
    width: 100%;
  }

  .calender_wrppaer {
    /* width: 300px; */
    overflow-x: scroll;
  }

  .calender_wrppaer li {
    margin-right: 25px;
  }

  .sports_carousel_wrap {
    margin-bottom: -389px;
  }

  .info-box h3 {
    font-size: 1.5rem !important;
    text-align: left;
    font-weight: bold !important;
    color:white !important
  }

  .info-box p {
    font-size: 1rem !important;
    text-align: left;
  }
  .schedule-row {
    padding-left: 50px;
  }

  /* .calender_wrppaer {
    margin-top: 10rem;
  } */
}

/* Target devices between 601px and 767px */
@media (min-width: 601px) and (max-width: 767px) {
  .info-box h3 {
    font-size: 1.5rem !important;
    text-align: left;
    font-weight: bold !important;
    color:white !important
  }

  .info-box p {
    font-size: 1.2rem !important;
    text-align: left;
  }
  .schedule-row {
    padding-left: 50px;
  }
}

.calendar-icon-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.calendar-icon {
  font-size: 16px;
  cursor: pointer;
  color: #333;
}

.calender-padding {
  padding-left: 4% !important;
  padding-bottom: 30px !important;
  padding-top: 4%;
}

.calender_wrppaer li {
  cursor: pointer;
}

.calender_wrppaer::-webkit-scrollbar {
  height: 7px; /* Adjust scrollbar height */
}

.calender_wrppaer::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Customize scrollbar color */
  border-radius: 3px;
}

@media screen and (max-width:600px) {
  .calender-padding {
    /* padding-top: 30%; */
}
}

