.score-main {
  grid-template-columns: 20% 20% 60%;
  display: grid;
  justify-content: center;
  padding: 50px 20px 10px 100px;
  gap: 30px;
  height: 100%;
  // background-color: rgba(0, 31, 76, 0.9);
  background-color: #ffffff;
}

.score-main-new {
  grid-template-columns: 20% 80%;
  display: grid;
  justify-content: center;
  padding: 50px 20px 10px 100px;
  gap: 30px;
  height: 100%;
  // background-color: rgba(0, 31, 76, 0.9);
  background-color: #ffffff;
}



.sports {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  color: #812c23;
  font-family: "Paris2024-Variable";
  font-size: 20px;
  font-weight: 400;
  margin-top: 2rem;
}

.sports-active-btn {
  width: fit-content;
  padding: 5px 30px;
  text-align: center;
  border-radius: 10px;
  // background: var(--hhhhh, linear-gradient(90deg, #174b95 0%, var(--heading-color) 100%));
  background-color: #812c23;
  color: #fff;
  
}

.sport-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 16px;
  // background: var(--hhhhh, linear-gradient(90deg, #174b95 0%, var(--heading-color) 100%));
  background-color: #812c23;
  padding: 25px;
  color: #ffff;
  height: fit-content;
  margin-top: 1.5rem;
}

.olympic-img {
  width: 40px;
  height: 40px;
}

.sport-description .des {
  text-align: center;
  color: #f2f0f0;
  text-align: center;
  font-family: Lato;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3rem;
}

.learn-more {
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase;
}

.learn-more p {
  margin: 0px;
}

// .sport-list {
//   display: grid;
//   grid-template-columns: repeat(3, minmax(0, 1fr)); /* Ensures three cards per row with equal width */
//   gap: 0px;
//   row-gap: 0px;
//   justify-content: start;
//   max-height: 100%;
//   overflow-y: auto; /* Enable vertical scrolling */
//   // background-color: red;
//   justify-content: start; /* Align items to the start horizontally */
//   align-items: start; /* Align items to the start vertically */
//   justify-items: start;
// }

.sport-list {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 5px;
  justify-content: start;
  align-items: start;
  max-height: 100%;
  overflow-y: auto;
}

.sports-list-score {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin: 20px 0;
  overflow-y: auto;
}

@media (max-width: 900px) {

.sport-image {
  width: 100px !important;
  
}
}

.sport-list-games {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 5px;
  justify-content: start;
  align-items: start;
  max-height: 100%;
  overflow-y: auto;
}

.sport-list-latest {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 5px;
  justify-content: start;
  align-items: start;
  max-height: 100%;
  overflow-y: auto;
}


.sport-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  // background-color: #f9f9f9;
}

.sport-image {
  width: 200px;
  height: auto;
  object-fit: contain;
}

.no-image-message {
  grid-column: span 5; /* Center the message across the row */
  text-align: center;
  font-size: 18px;
  color: #666;
  margin: 20px 0;
}

.indigenous-sports {
  max-height: 70%;
}

.each-game {
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  display: flex;
  height: max-content;
}

.image-container-dropdowns {
  width: 50px; /* Adjust as per your need */
  height: 50px; /* Adjust as per your need */
  background-color: var(--heading-color); /* Background color */
  clip-path: polygon(
    50% 0%,
    93% 25%,
    93% 75%,
    50% 100%,
    7% 75%,
    7% 25%
  ); /* Hexagon shape */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-top: 20px;
}

.image-container-dropdowns-allsport {
  width: 90px; /* Adjust as per your need */
  height: 120px; /* Adjust as per your need */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-top: 20px;
  img {
    width: 75px !important;
    height: 85px !important;
  }
}

.image-container-dropdowns:hover {
  // background-image: linear-gradient(90deg, var(--heading-color) 0%, #0B9444 100%);
  cursor: pointer;
}

.each-game img {
  width: 30px;
  height: 22px;
  // display: block;
  // margin-top: 20px;
}

.each-game .des {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 5px;
}

.name {
  color: #812c23;
  text-align: left;
  font-family: Lato;
  font-size: 20px;
  font-weight: 600;
  margin: 0px;
}

.description {
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  text-align: start;
  color: #812c23;
  text-align: justify;
}

@media (max-width: 768px) {
  .sport-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .sport-list {
    grid-template-columns: 1fr;
  }
}

.ktspl-Logo-subnav {
  position: absolute;
  bottom: 5%;
  left: 1%;
  img {
    width: 8rem;
    height: 7rem;
  }
}
