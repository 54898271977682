.teams-list {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 5px;
  justify-content: start;
  align-items: start;
  max-height: 100%;
  overflow-y: auto;
}

.top_nav-teams {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 16px;
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 25px;
    position: relative;
    z-index: 20;
    height: 90vh;
     /* Hide scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  background-color: rgba(0, 31, 76, 0.9);
    
  }
  
  .sport-description-teams {
    min-height: 200px !important; /* Example: Ensure minimal height */
   
  }
  