.chat-intercom-main {
  overflow-y: scroll;
  position: fixed;
  right: 0;
  bottom: 90px;
  z-index: 999;
  background-color: #fff;
  height: 430px;
  max-width: 400px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--heading-color);
}

/* Custom scrollbar styles */
.chat-intercom-main::-webkit-scrollbar {
  width: 8px; /* Reduces the scrollbar width */
}

.chat-intercom-main::-webkit-scrollbar-track {
  background: #fff; /* Matches the chat container background */
  border-radius: 10px;
}

.chat-intercom-main::-webkit-scrollbar-thumb {
  background-color: var(--heading-color); /* Matches the border color */
  border-radius: 10px; /* Smooth, rounded edges */
  border: 2px solid #fff; /* Adds padding around the scrollbar thumb */
}

/* Optional for better support in Firefox */
.chat-intercom-main {
  scrollbar-color: var(--heading-color); /* Scroll thumb and track colors */
  scrollbar-width: thin; /* Makes the scrollbar smaller */
}

.chatbot-close-button {
  position: absolute;
  font-size: 35px;
  color: #000;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

@media (max-width: 660px) {
  .chat-intercom-main {
    right: 0;
    bottom: 70px;
    height: 440px;
    max-width: 400px;
  }
  .chatbot-close-button {
    font-size: 45px;
  }
}

.chat-home-head {
  display: grid;
  grid-template-rows: 150px 1fr;
  padding: 30px;
  /* background-color: var(--button-secondary-color); */
  background-color: var(--maroon-light);
  /* background: linear-gradient(to right, #AA27D1, #7121D4); */
  justify-items: center;
  align-items: center;
  gap: 22px;
}
.homeLogo {
  /* width: 65px; */
  height: 65px;
  border-radius: 50%;
}
.moga-Logo {
  width: 70px;
  /* height: 100px; */
  aspect-ratio: 3/2;
  border-radius: 50%;
}

.national-Logo {
  width: 180px;
  aspect-ratio: 3/2;
}

.chat-home-head h1 {
  font-size: 32px;
  line-height: 38px;
  font-weight: 600;
  color: var(--heading-color);
}

.chat-home-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 10px;
  /* margin-top: 135px; */
}
.chatbot-home-username {
  height: 50px;
  font-size: 1.1rem;
  line-height: 2.5rem;
  text-align: center;
  border-radius: 5px;
  /* border: solid 1px #00000087; */
  border: unset;
  margin: 10px;
  width: 100%;
  color: black;
  padding: 10px 30px;
  /* background-color: var(--button-secondary-color); */
  background-color: var(--maroon-light);
  outline: unset;
  border: 1px dashed var(--heading-color);
}
.chatbot-home-start {
  text-align: center;
  border-radius: 5px;
  padding: 10px 30px;
  font-size: 1.2rem;
  color: #fff;
  background: var(--heading-color);
  border: unset;
  width: 100%;
  height: 50px;
  margin: 10px;
}

.chatbot-header {
  display: flex;
  flex-direction: column;
  padding: 8px;
  background-color: var(--heading-color);
  color: #fff;
}
.intercom-header {
  display: flex;
  align-items: center;
  gap: 10px;
}
/* .intercom-header svg {
  margin-right: 99px;
} */
.intercom-header-name {
  font-size: 25px;
  margin-bottom: 0px !important;
  font-weight: 600;
}

.mogo-desc {
  font-size: 11px;
  letter-spacing: 1px;
  margin-bottom: 0px !important;
}

.intercom-profile {
  display: flex;
  flex-direction: column;
}

.intercom-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 30px;
}

.intercom-body img {
  width: 50px;
  height: 50px;
}

.intercom-body h1 {
  font-size: 15px;
  font-weight: 600;
}

.intercom-body p {
  font-size: 13px;
  font-weight: 500;
  color: #ddd;
}

.Conversation-main {
  display: grid;
  grid-template-rows: min-content 1fr;
  overflow: auto;
  height: 100%;
}

.message-main {
  display: flex;
  gap: 8px;
  align-items: center;
}

.messageLogo {
  width: 15px;
  height: 30px;
  border-radius: 50%;
  box-shadow: 0px 0px 16px rgb(0, 0, 0, 0.1);
}

.message-main.sender {
  justify-content: flex-end;
}

.message-text {
  position: relative;
  margin: 0px;
  border-radius: 10px;
  font-size: 0.9rem;
  word-break: break-word;
  border: 1px solid gray;

  /* background-color: var(--heading-color); */
  /* background-image: linear-gradient(45deg, #65379b, #886aea); */
  border-radius: 10px;
  padding: 10px;
  color: #000;
}

.message-text.sender {
  background-color: #ddd;
  color: #000;
}

.Conversation-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  overflow: auto;
  /* background-image: linear-gradient(45deg, #65379b, #886aea); */
}

.chat-option {
  position: relative;
  color: var(--heading-color);
  border: 1px solid var(--heading-color);
  background-color: #fff;
  border-radius: 10px;
  width: fit-content;
  word-break: break-word;
  padding: 6px 10px;
  font-size: 0.9rem;
}

.chat-option:hover {
  color: #fff;
  background-image: linear-gradient(
    to right,
    var(--accent-color),
    var(--accent-color)
  );
}

.inner-link {
  color: blue !important;
  font-weight: 500 !important;
}

.option-body {
  display: flex;
  flex-wrap: wrap;
  gap: 0px 7px;
}

.option-main {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}

.status {
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  color: #5bff5b;
  font-size: 9px;
}

.status-circle::before {
  content: "\f111";
}

.blink_status {
  animation: blinker 1.5s linear infinite;
}

@keyframes blink_status {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
