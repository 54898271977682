.active_arrow_icon {
  color: var(--white-text-color);
}

.navbar-main {
  position: sticky;
  top: 0;
  z-index: 9000;
  width: 100%;
}

.mobile-nav-container {
  display: none;
}

.mobile-navbar {
  /* background: var(--blue-fff, var(--heading-color)); */
  /* color: white; */
  /* text-align: center; */
  display: none;
  background-image: linear-gradient(90deg, #e9ecef 29.17%, #ffffff 68.54%);
  backdrop-filter: blur(20px);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  padding: 10px 10px 3px 10px;

  z-index: 10000;
  font-family: "Paris2024-Variable";
  height: 60px;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px; */
}

@media (max-width: 760px) {
  .mobile-nav-container {
    display: block;
  }
}

.mobile_header_logo {
  padding-bottom: 5px;
}

.mobile_header_logo img {
  width: 85px;
  object-fit: contain;
  height: fit-content;
}

/* @media (max-width: 660px) {
  .navbar-main {
    display: none;
  }
} */

.top-nav-info {
  background: var(--blue-fff, var(--heading-color));
  color: white;
  text-align: center;
  padding: 1rem;
}

.hamburgerMenu {
  display: none;
}

.navbar-fields.scrolled {
  background-color: var(--card-background-color);
  backdrop-filter: blur(20px);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.navbar-fields {
  /* background-image: linear-gradient(
    90deg,
    rgba(48, 156, 239, 0.5) 29.17%,
    rgba(1, 75, 144, 0.5) 68.54%
  ); */
  /* background-color:#087CDF ; */
  /* background-color: black ;  */
  /* backdrop-filter: blur(20px); */
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  width: 100%;
  padding: 10px 10px 3px 10px;
  z-index: 10000;
  font-family: "Paris2024-Variable";
  top: -3px;
  background-color: var(--card-background-color);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.nav-items-dropdows {
  color: white;
  max-width: fit-content;
  display: flex;
  gap: 2rem;
  font-family: "Inter", sans-serif;
  margin-top: 0.8rem;
}

.navLogo {
  width: 200px;
}

.nav-items-dropdows h6 {
  color: #4d4d4d;
  font-family: Inter;
  font-size: 1rem;
  font-family: "Paris2024-Variable";
  font-weight: 500;
  line-height: 1.625rem;
  text-transform: uppercase;
  cursor: pointer;
}

/* .bi-chevron-down {
  color:var(--link-color);
  font-size: 0.6rem;
  padding-left: 5px;
} */
.menu_search_box {
  border: 1px solid #fff;
  width: 29rem;
  height: 40px;
  border-radius: 1.2rem;
  display: flex;
  /* Use flexbox to align items */
  align-items: center;
  /* Center align the icon and input vertically */
  overflow: hidden;
  /* Prevent overflow */
}

.menu_search_box div {
  display: flex;
  align-items: center;
  width: 100%;
  /* Ensure the div takes the full width of the parent */
}

.menu_search_box i {
  color: #fff;
  padding-left: 13px;
  /* Add padding to position the icon */
}

.menu_search_box input {
  background-color: transparent;
  border: none;
  color: #fff;
  outline: none;
  width: 100%;
  /* Make the input fill the remaining space */
  padding-left: 8px;
  /* Space between the icon and text */
  box-sizing: border-box;
  /* Ensure padding is included in width calculation */
}

.menu_search_box input::placeholder {
  color: #fff;
}

.nav-top-subhead {
  color: #fff;
  font-family: Lato;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.bi-search {
  color: white;
  padding: 0rem 0.5rem 0rem 1rem;
}

.left-navbar-items,
.right-navbar-items {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.searchBtn {
  display: none;
}

.burger-container {
  flex: 0 0 auto;
  /* Burger menu on the left */
}

.hamburgerMenu_menu {
  background-color: var(--background-color);
  color: var(--white-text-color);
  padding: 5px 10px;
  /* border-radius: 25px 10px; */
  border: 50%;
  border-radius: 50%;
}

/* logo listing */
/* .logo_wrapper_mobile {
  display: none;
} */
.hamburgerMenu_menu_icon {
  font-size: 14px;
  margin-top: -3px;
}

.dowen_arrow {
  font-size: 12px;
  margin-left: 4px;
}

@media screen and (max-width: 600px) {
  .mobile-navbar {
    height: 60px;
  }
}
@media (min-width: 750px) and (max-width: 1200px) {
  .mobile-nav-container {
    display: block;
  }
  .navbar-main {
    display: none;
  }
}
@media (max-width: 1290px) {
  .navbar-fields {
    justify-content: space-around;
  }

  .menu_search_box {
    width: 14rem;
  }
}

@media (max-width: 1090px) {
  .nav-items-dropdows,
  .menu_search_box {
    display: none;
  }

  .hamburgerMenu {
    display: block;
  }

  /* logo hide */

  .navLogo {
    display: none;
  }

  .navbar-fields {
    background-image: none;
    background-color: var(--background-color);
    display: flex;
    justify-content: center;
  }

  .header-img {
    display: none;
  }

  .left-navbar-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .searchBtn {
    display: block;
  }

  .login-btn-none {
    display: none;
  }

  .navlogo-container {
    flex: 1;
    display: flex;
    justify-content: center;
    /* Center the NavLogo */
  }

  .left-navbar-items > div:nth-child(2) {
    /* Center the second div ---*/
    margin: 0 auto;
  }
}

.sub-navbar-main {
  fill: rgba(0, 31, 76, 0.9);
  /* backdrop-filter: blur(20px); */
  background-color: var(--heading-color);
  position: relative;
  height: 100vh;
  padding-top: 5rem;
}

.navlogo-sou {
  width: 6rem !important;
}

.navlogo-gou {
  width: 5rem !important;
}

.navbar-imgs img {
  width: 8.5rem;
  height: 5rem;
}

/* hambuger */

.mobile-menu {
  display: none;
  font-family: Paris2024-Variable;
}

.ioa-safeguard-mobile {
  font-weight: 600;
}
.fan-zone-btn-mobile {
  font-weight: 600;
  font-family: Paris2024-Variable;
}

@media (max-width: 991px) {
  .logo_wrapper_mobile_container {
    display: flex;
    justify-content: center;
    /* padding: 1rem 0rem 6rem 0rem; */
  }

  .logo_wrapper_mobile {
    background-color: white;
    position: absolute;
    top: 5rem;
    border-radius: 50px;
    padding: 8px 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .logo_wrapper_mobile img {
    cursor: pointer;
  }
  .mobile-menu {
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 1000;
    padding: 1rem;
    padding-top: 7rem;
    /* fill: rgba(0, 31, 76, 0.9); */
    backdrop-filter: blur(20px);
    background-color: var(--secondary-background-color);
    color: var(--heading-color);
    overflow-y: scroll;
    transition: transform 0.3s ease-in-out;
  }

  .mobile-menu-item {
    margin-bottom: 1rem;
  }

  .mobile-menu-item h6 {
    cursor: pointer;
  }

  .mobile-submenu {
    list-style-type: none;
    padding-left: 1rem;
  }

  .mobile-submenu li {
    margin-top: 0.5rem;
  }

  .nav-items-dropdows {
    display: none;
  }

  .mobile-menu-item {
    border-radius: 10px;
    /* padding: 0.5rem; */
  }

  .mobile-submenu-list-item {
    border-radius: 10px;
    background: var(--background-light-color);
    padding: 0.5rem;
    transition: background-color 0.3s ease;
  }

  .mobile-submenu-list-item > a {
    text-decoration: none;
    color: #fff;
  }

  .submenu_title {
    color: #fff;
  }

  .list-item {
    border-radius: 10px;
    background: var(--background-color);
    padding: 1rem;
    color: #fff;
    font-weight: bold;
  }

  .mobile-submenu {
    list-style-type: none;
    padding-left: 1rem;
    /* max-height: 0; */
    overflow: auto;
    transition: max-height 0.3s ease-in-out;
  }

  .mobile-menu-item.open .mobile-submenu {
    max-height: 500px;
  }
}

/* //// */
.popup-overlay-header {
  position: fixed;
  top: 64px;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content-header {
  background: var(--background-color);
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  height: 90%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.back-button-header {
  background: none;
  border: none;
  color: #007bff;
  font-size: 1.2rem;
  cursor: pointer;
  text-align: left;
  margin-bottom: 20px;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-header h4 {
  margin: 0;
  font-size: 1.5rem;
}

.sports-list {
  list-style-type: none;
  padding: 0;
  margin: 15px 0px 0px 0px;
}

.sports-list li {
  padding: 10px 0;
  /* border-bottom: 1px solid #ddd; */
  font-size: 1rem;
}

/* /////// */

.fade-transition {
  transition: opacity 0.8s ease-in-out;
  /* Smooth transition for fade effect */
  opacity: 1;
  /* Fully visible state */
}

.fade-transition.fading {
  opacity: 0;
  /* Fade-out state */
}

@media screen and (max-width: 600px) {
  .navbar-fields {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

@media screen and (max-width: 600px) {
  .mobile_header_logo img {
    width: 55px;
  }
}
.live-match-btn {
  border: none;
  max-width: 70%;
  background: linear-gradient(135deg, #fff, #fff);
  color: var(--heading-color);
  border: 1px solid var(--heading-color);
  padding: 6px 12px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 18px;
  font-family: "Paris2024-Variable";
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; /* Space between text and dot */
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  position: relative;
}

@media (max-width: 1082px) {
  .live-match-btn {
    display: none;
  }
}
.fan-zone-btn {
  border: none;
  max-width: 40%;
  background: linear-gradient(135deg, #fff, #fff); /* Maroon gradient */
  color: var(--heading-color); /* White text for contrast */
  padding: 8px 20px; /* Larger padding for better visibility */
  border-radius: 50px; /* Smooth, pill-like shape */
  cursor: pointer;
  font-size: 15px; /* Larger font for emphasis */
  font-family: "Paris2024-Variable";
  text-align: center;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  transition: all 0.3s ease; /* Smooth hover animation */
  border: 1px solid var(--heading-color);
  white-space: nowrap;
}

.ioa-safeguard-helpline {
  max-width: 55%;
}

@media (max-width: 1082px) {
  .fan-zone-btn {
    display: none;
  }
}

.fan-zone-btn:hover {
  transform: translateY(-5px); /* Lift effect */
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.3); /* Stronger shadow on hover */
}

.live-match-btn:hover {
  background: linear-gradient(
    135deg,
    #fff,
    #fff
  ); /* Reverse gradient on hover */
  transform: translateY(-5px); /* Lift effect */
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.3); /* Stronger shadow on hover */
}

.fan-zone-btn:active,
.live-match-btn:active {
  transform: translateY(0); /* Return to original position */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

/* Green dot animation */
.live-indicator {
  width: 7px;
  height: 7px;
  background-color: #21a121; /* Bright green */
  border-radius: 50%;
  box-shadow: 0 0 5px #6b9c6b;
  animation: livePulse 1.5s infinite alternate;
}
.indicator {
  width: 1px I;
  height: 13px;
  background-color: #21a121; /* Bright green */
  border-radius: 50%;
  box-shadow: 0 0 5px #6b9c6b;
  animation: livePulse 1.5s infinite alternate;
  border: none;
}

/* Pulsing animation */
@keyframes livePulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0.6;
  }
}
