html,
body,
#root {
  box-sizing: border-box;
  width: 100%;
}

* {
  box-sizing: inherit;
}

@media (max-width: 660px) {

  html,
  body,
  #root {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }

  * {
    box-sizing: inherit;
  }
}

.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.postion_rel {
  position: relative;
}

.h_line_width {
  width: 8vh;
}

@font-face {
  font-family: "Paris2024-Variable";
  src: url("./assets/fonts/Paris2024-Variable.ttf");
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.dots {
  margin-top: -100px;
}

.blogcontainer {
  height: 100px;
  margin-left: 100px;
}

.linergradient {
  background-image: linear-gradient(to right, var(--heading-color), #0B9444);
}

.sport_cube_box {
  border-radius: 8px;
  background: #EEE;
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;

}

.sport_cube_box_white {
  border-radius: 8px;
  background: #FFF;
  width: 55px;
  height: 55px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  align-items: center;
}

.footer_heading {
  color: #fff;
  font-family: Paris2024-Variable;
  font-size: 20px;
  font-style: normal;
  font-weight: 550 !important;
  line-height: normal;
  text-transform: uppercase;
}

.Sport_title {
  color: var()var(--heading-color) !important;
  font-family: "Paris2024-Variable";
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 46px;
  /* 153.333% */
}

.sport_Date_list_wrap {
  list-style-type: none;

}

:root {
  /* Text Colors */
  --heading-color: #9D2D20;
  --heading-black-color: #000000;
  --heading-light-color: #EBD5D2;
  --white-text-color: #ffffff;
  --subheading-color: #555555;
  /* Slightly lighter grey for subheadings */
  --paragraph-color: #626262;
  /* Light grey for paragraph text */
  --link-color: #00000099;
  /* Blue for links */
  --link-hover-color: #1e90ff;
  /* Lighter blue for link hover state */
  --maroon-light: #f5eae9;


  /* Background Colors */
  --background-color: #9D2D20;
  /* White background */
  --background-light-color: #CE968F;
  --secondary-background-color: #ffffff;
  /* Light grey background */
  --card-background-color: #fdfdfd;
  /* Slightly off-white for cards */
  --footer-background-color: #2c3e50;
  /* Dark grey for footer */

  /* Accent and Border Colors */
  --accent-color: #e74c3c;
  /* Red for accents like buttons */
  --border-color: #dddddd;
  /* Light grey for borders */
  --error-color: #ff0000;
  /* Red for error messages */
  --success-color: #2ecc71;
  /* Green for success messages */

  /* Button Colors */
  --button-text-white-color: #FFFFFF;
  --button-border-color: #9D2D20;
  --button-maroon-text-color: #9D2D20;
  --button-primary-color: #3498db;
  /* Primary button color */
  --button-secondary-color: #95a5a6;
  /* Secondary button color */
  --button-hover-color: #2980b9;
  /* Hover state for buttons */
}






body {
  --bg-nav-footer: #8E58E4;
  --bg--heading: #ba068d;

  --bg-underline: #ed9e10;
  --bg-dark: #434343;
  --bg-grey: #ecedef;

  font-family: "Lato";
  overflow-x: hidden;
  overflow-y: auto;
}

.bg-nav-footer {
  background-color: var(--bg-nav-footer);
}

.bg-heading {
  color: var(--bg--heading);
}

.bg-underline {
  color: var(--bg-underline);
  text-decoration: underline;
}

.bg-dark-app {
  background-color: var(--bg-dark);
}

.font-12 {
  font-size: 12px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-24 {
  font-size: 24px;
}

.font-26 {
  font-size: 26px;
}

.font-28 {
  font-size: 28px;
}

.font-30 {
  font-size: 30px;
}

.font-32 {
  font-size: 32px;
}

.font-35 {
  font-size: 35px;
}

.font40 {
  font-size: 40px;
}

.font-45 {
  font-size: 45px;
}

.black_col {
  color: #475653;
}

.space5 {
  height: 5px;
  clear: both;
}

.space10 {
  height: 10px;
  clear: both;
}

.space15 {
  height: 15px;
  clear: both;
}

.space20 {
  height: 20px;
  clear: both;
}

.space30 {
  height: 30px;
  clear: both;
}

.space40 {
  height: 40px;
  clear: both;
}

.space50 {
  height: 50px;
  clear: both;
}

.center_title {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 576px) {
  .h_line_width {
    width: 5vh;
  }
}


.wrapper_Container {
  position: relative;
}

.page_title_wrap {
  position: absolute;
  top: 175px;
  z-index: 10;
  left: 75px;
}

@media (max-width: 660px) {
  .page_title_wrap {
    top: 90px;
    left: 15px;
  }
}

@media (min-width: 768px) and (max-width: 850px) {
  .page_title_wrap {
    top: 85px;
    left: 65px;
  }
}


@media (max-width: 767px) {
  .page_title_wrap {
    top: 50px;
    left: 15px;
  }
}

.page_Title_text {
  color: #fff;
  font-size: 1rem;
  text-decoration: none;
  font-family: "Noto Sans";
  text-transform: capitalize;
}

.arrow_right_icon {
  font-size: 2rem;
  color: var(--heading-color);
  font-weight: 400;

}

.list_style_none {
  list-style: none;
}

.new-topClock-design {
  position: absolute !important;
  background-color: #ffffff !important;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2) !important;
  /* Softer shadow for a polished look */
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 60px 20px 0px;
  bottom: -67px !important;
}

.countdown-text {
  font-family: "Paris2024-Variable";
  font-size: 26px;
  font-weight: bold;
  color: #333;
  text-align: center;
}

.event-logo {
  width: 150px !important;
  /* Adjust logo size */
  height: auto !important;
  margin-left: 60px;
}

.home-route {
  position: absolute !important;
  background-color: #ffffff !important;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2) !important;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px 20px 10px;
  bottom: -40px;
  z-index: 99;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
}

.wrapper_containers {
  position: relative;
  overflow: hidden;
}


.gallery_viewMore_btn {
  border: 1px solid var(--heading-color);
  color: var(--heading-color);
  background: none;
  padding: 0.5rem 1rem;
  border-radius: 1.7rem;
  cursor: pointer;
  transition: all 0.3s ease;
  align-self: flex-start;
  margin-top: auto;
}

.gallery_viewMore_btn:hover {
  color: #fff;
  border-color: var(--heading-color);
  background: var(--background-color);
}

.leaderboard_table{
  border:none!important;
}

.leaderbored_wrapper tbody, td, tfoot, th, thead, tr {
  border-color: inherit;
  border-style: none;
  border-width: 0;
}

.leaderboard_img {
  width: 40px;
  object-fit: contain;
  height: 40px; /* Ensure the height matches the width */
  border-radius: 50%; /* Makes the image circular */
  /* Ensures the image doesn't stretch */
  border: 2px solid #ddd; /* Optional: Add a border for better visibility */
}

.nodata_title{
  font-family: "lato";
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  text-align: center;
}



@media screen and (max-width:600px) {
  .new-topClock-design {
    flex-direction: column;
    padding: 15px;
    text-align: center;
    top: 235px;
    height: 100%;
    /* top: 265px; */
  }

  .margin_top_view_mobile {
    margin-top: 15rem;

  }

  .home-route {
    position: initial !important;
    background-color: #ffffff !important;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2) !important;
    /* Softer shadow for a polished look */
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 60px 20px 0px;
    bottom: -67px !important;
    z-index: 1000;
    flex-direction: column;
    padding: 0px;
    z-index: 0;
    transform: none;
  }

  .banner-image {
    height: 300px !important;
    border-bottom-left-radius: 5rem;
    border-bottom-right-radius: 5rem;

  }

  .event-logo {
    margin-left: 0;
    padding-top: 20px;

  }

  .breadcrumb {
    padding: 15px 0;
  }

}

.stages_div {
  padding: 10px 10px !important;
}

.stages_div_button {
  padding: 20px 47px !important;
}

@media (max-width: 767px) {
  .stages_div {
    padding: 7px 7px !important;
  }

  .stages_div_button {
    padding: 5px 11px !important;
    /* margin-top: 5rem; */
  }
}

.sports_margin_top {
  margin-top: -6.5% !important;
}

.sports_elimination_margin_top {
  margin-top: -3.5% !important;
}

.stages_elimination_width {
  margin-left: 3% !important;
}

/* Target devices between 1200px and 1440px */
@media (min-width: 1200px) and (max-width: 1440px) {
  .sports_margin_top {
    margin-top: -7% !important;
  }

  .sports_elimination_margin_top {
    margin-top: -5% !important;
  }

}

/* Target devices between 1023px and 1200px */
@media (min-width: 1023px) and (max-width: 1200px) {
  .sports_margin_top {
    margin-top: -8% !important;
  }

  .sports_elimination_margin_top {
    margin-top: -7% !important;
  }

}


/* Target devices between 768px and 1024px */
@media (min-width: 768px) and (max-width: 1022px) {
  .sports_margin_top {
    margin-top: 0% !important;
  }

  .stages_width {
    width: 50% !important;
    margin-left: 25% !important
  }

  .sports_elimination_margin_top {
    margin-top: 5% !important;
    margin-bottom: 2rem !important;
  }

  .stages_elimination_width {
    margin-left: 50% !important;
  }
}

/* Target devices between 768px and 1024px */
@media (min-width: 425px) and (max-width: 766px) {
  .sports_margin_top {
    margin-top: 0% !important;
  }

  .sports_elimination_margin_top {
    margin-top: 5% !important;
    margin-bottom: 2rem !important;
  }

  .stages_elimination_width {
    margin-left: 19% !important;
  }
}

/* Target devices between 768px and 1024px */
@media (min-width: 320px) and (max-width: 425px) {
  .sports_margin_top {
    margin-top: 0% !important;
  }

  .sports_elimination_margin_top {
    margin-top: 5% !important;
    margin-bottom: 2rem !important;
  }

  .stages_elimination_width {
    margin-left: 18% !important;
  }
}

.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: 0rem !important;
  color: none !important;
  content: "";
}

.schedule-margin-top {
  margin-top: 2% !important;
}

.cluster-dropdown {
  display: flex;
  justify-content: end;
}

.gender-dropdown {
  display: flex;
}

.sportlist-dropdown {
  display: flex;
}

@media (min-width: 320px) and (max-width: 424px) {
  .w-xxl-80 {
    width: 140% !important;
  }

  .schedule-india-margin {
    margin-top: -175px;
    width: 50px;
    margin-left: 83%;
    padding: 0px !important;
  }

  .shedule_active_menu_container {
    padding: 10px 25px !important;
    margin-left: 40px;
  }

  .schedule__menu_title {
    padding: 5px 7px !important;
  }

  .select_drop_dwn {
    width: 270px !important;
    height: 40px !important;
  }

  .dropdown-sport {
    margin-bottom: 10% !important;
  }

  .cluster-dropdown {
    display: flex;
    justify-content: space-around;
  }

  .gender-dropdown {
    display: block !important;
  }

  .schedule_uttar_img {
    width: 40%;
  }

  .schedule_india_img {
    width: 80px;
  }

  .schedule-margin-top {
    margin-top: 5% !important;
    padding-left: 3% !important;
    padding-right: 3% !important;
  }

  .calender_margin_top {
    /* margin-top: 150px !important; */
  }

  /* .overiew_margin_top {
    margin-top:2rem !important;
  } */
  .sportlist-dropdown {
    display: flex;
    justify-content: space-around;
  }
}

@media (min-width: 425px) and (max-width: 575px) {
  .w-xxl-80 {
    width: 200% !important;
  }

  .schedule_uttar_img {
    width: 30%;
  }

  .schedule_india_img {
    width: 80px;
  }

  .sportlist-dropdown {
    display: flex;
    justify-content: space-around;
  }
}

@media (min-width: 425px) and (max-width: 499px) {
  .schedule-india-margin {
    margin-top: -175px;
    width: 50px;
    margin-left: 83%;
    padding: 0px !important;
  }

  .shedule_active_menu_container {
    padding: 10px 10px !important;
  }

  .schedule__menu_title {
    padding: 5px 25px !important;
  }

  .schedule-margin-top {
    margin-top: 2% !important;
  }

  .calender_margin_top {
    /* margin-top: 120px !important; */
  }

  .select_drop_dwn {
    width: 270px !important;
    height: 40px !important;
  }

  .dropdown-sport {
    margin-bottom: 10% !important;
  }

  .cluster-dropdown {
    display: flex;
    justify-content: space-around;
  }

  .gender-dropdown {
    display: block !important;
  }

  /* .overiew_margin_top {
    margin-top:1rem !important;
  } */

}

@media (min-width: 500px) and (max-width: 575px) {
  .schedule-india-margin {
    margin-top: -185px;
    width: 50px;
    margin-left: 80%
  }

  .calender_margin_top {
    /* margin-top: 200px !important; */
  }

  .select_drop_dwn {
    width: 370px !important;
    height: 40px !important;
  }

  .dropdown-sport {
    margin-bottom: 10% !important;
  }

  .cluster-dropdown {
    display: flex;
    justify-content: space-around;
  }

  .gender-dropdown {
    display: block !important;
  }

  /* .overiew_margin_top {
    margin-top:2rem !important;
  } */
}


@media (min-width: 576px) and (max-width: 768px) {
  .w-xxl-80 {
    width: 190% !important;
  }

  /* .schedule-margin-top {
    margin-top:140px !important;
  } */
  .calender_margin_top {
    /* margin-top: 230px !important; */
  }

  .overiew_margin_top {
    margin-top: 200px !important;
  }
}

@media (min-width: 769px) and (max-width: 991px) {
  .w-xxl-80 {
    width: 150% !important;
  }

  /* .schedule-margin-top {
    margin-top:140px !important;
  } */
  .calender_margin_top {
    /* margin-top: 230px !important; */
  }

  .overiew_margin_top {
    margin-top: 300 !important;
  }
}


@media (min-width: 992px) and (max-width: 1100px) {
  .w-xxl-80 {
    width: 160% !important;
  }
}

@media (min-width: 1440px) and (max-width: 1919px) {

  /* 'xxl' breakpoint */
  .w-xxl-80 {
    width: 85% !important;
  }
}

@media (min-width: 1920px) {

  /* 'xxl' breakpoint */
  .w-xxl-80 {
    width: 80% !important;
  }
}

@media (min-width: 992px) {
  .grid-cols-lg-12 {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }
}

.color-maroon {
  color: #9d2d20;
}

.bgColor-maroon {
  background-color: #9d2d20;
}

.color-maroon-light {
  background-color: #CE968F;
}

.border-maroon {
  border: 1px solid #9d2d20;
}

.bgColor-gradient-maroon {
  background: linear-gradient(180deg, #9D2D20 0%, #37100B 100%)
}

.vertical-timeline-element-content {
  position: static !important;
}

.hover-blue .hover-target:hover {
  color: #0a5aca !important;
  transition: color 0.3s ease;
  /* Smooth transition effect */
}
.grivence {
  color: rgba(255, 255, 255, 0.5) !important;
  text-decoration: none !important;
}
.grivence:hover {
  color: #ffffff !important;
  text-decoration: underline !important;
}

.vertical-timeline--two-columns .vertical-timeline-element-icon {
  text-align: center !important;
}

/* @media only screen and (min-width: 1170px) {
  .vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date{ 
    left: 55% !important;
  }
} */

.vertical-timeline--two-columns .vertical-timeline-element-icon {
  width:60px !important;
  height:60px !important;
}

@media (min-width: 426px) {
  .view-score-3xl-text {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  .view-score-xl-text {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .view-score-padding-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
}

