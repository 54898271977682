.schedule-info-container {
  color: white; /* Text color */
  padding: 20px 0; /* Padding for top and bottom */
  border: 1px solid rgba(239, 239, 239, 1)!important;
  background-color: rgba(151, 125, 125, 0.3)!important; /* Semi-transparent white */
  backdrop-filter: blur(10px); /* Adds blur effect */
  -webkit-backdrop-filter: blur(10px);
  width: 91%;
  /* margin-left: 4rem; */
  margin:auto !important;
  /* margin-bottom: 2rem !important; */
}


@media (max-width: 767px) {
  .schedule-info-container {
    width: 100%;
    margin: 0;
    background-color: inherit;
    backdrop-filter : none; 
    -webkit-backdrop-filter: blur(0px);
    border: none;
    margin: 0rem;
    padding: 0px 0px 10px 0px;
    
  }

  .info-box {
    padding-bottom: 2px !important;
    padding-top:2px !important
  }

}

.info-box h3 {
  font-size: 16px;
  margin: 0;
}

.info-box p {
  margin: 0;
  font-size: 24px;
}

/* Pseudo-elements to create dividers */
.schedule-row {
  position: relative;
}

.schedule-row .info-box {
  position: relative;
}

/* .schedule-row .info-box:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 95px;
  transform: translateY(-50%);
  width: 1px;
  height: 95px;
  background-color: white; 
} */

.sport_carosule_heading {
  color: #212121;
  font-family: "Paris2024-Variable";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sport_carosule_subtitle {
  color: #4d4d4d;
  font-family: "Paris2024-Variable";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sport_carosule_para {
  color: var(--heading-color);
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 42px;
}

.sport_carosule_date {
  color:#000000;
  font-family: "Paris2024-Variable";
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sport_carosule_btn_female {
  background-color: #c116aa;
  font-family: "Paris2024-Variable";
  font-weight: 400;
  color: white;
  border: 1px solid #c116aa;
  font-size: 12px;
}

.sport_carosule_btn_mixed {
  background-color: #9549cd;
  font-family: "Paris2024-Variable";
  font-size: 12px;
  font-weight: 400;
  color: white;
  border: 1px solid #9549cd;
}

.sport_carosule_btn_male {
  color: #fff;
  text-decoration: none;
  font-family: "Paris2024-Variable";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 4px;
  background: var(--uuuuu, var(--heading-color));
  padding: 5px 6px;
  width: 80px;
}

.sport_carosule_btn_view {
  color: #fff;
  text-decoration: none;
  font-family: "Paris2024-Variable";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 4px;
  background: var(--uuuuu, #817d7d);
  padding: 5px 25px;
}

.arrow_sport_Carosule {
  border-radius: 2px;
  background: var(--heading-color);
  height: 200px;
  color: #fff;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
} 

.sport_carosule_wrapper {
  display: flex;
  align-items: center; /* Aligns the arrows vertically centered with the carousel */
}

.previous_sport_Carosule,
.next_sport_Carosule {
  flex-shrink: 0; /* Prevent arrows from shrinking */
  cursor: pointer;
  padding: 0 1px;
}

.sport_carosule_container {
  flex-grow: 1; /* Makes the carousel take up the remaining space */
  overflow: hidden; /* Ensure content doesn't overflow */
}

.sports_carousel_wrap {
  margin-bottom: -250px;
}

.sport_carosule_card {
  width: 100%;
  text-align: left;
  border-radius: 20px;
  background: #fff;
  box-shadow: 2px 8px 20px 0px rgba(0, 0, 0, 0.1);
}

.sports_heading {
  margin-bottom: 1% !important;
  font-family: "Paris2024-Variable";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* background: linear-gradient(90deg, #19417a 0%, #0b9444 100%); */
  background-clip: text;
  background-color: var(--heading-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent; /* For browsers without webkit */
  background-size: 100%; /* Ensure the background gradient covers the text */
  display: inline-block; /* This ensures the background-size scales with the text */
}

.search-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px; /* Rounded corners */
  padding: 6px 12px;
  /* Width of the input field */
}

.search-icon {
  font-family: "FontAwesome"; /* Assuming you use Font Awesome */
  content: "\f002"; /* Unicode for search icon in Font Awesome */
  font-size: 16px;
  margin-right: 8px;
}

.search-input {
  border: none;
  outline: none;
  font-size: 16px;
  flex: 1;
}

/* To remove the default input outline when focused */
.search-input:focus {
  outline: none;
}

.sport_event_Card_heading {
  color: #212121;
  font-family: "Paris2024-Variable";
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sport_event_Card_para {
  color: #4d4d4d;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sport_event_Card_date {
  color: #646363;
  font-family: "Paris2024-Variable";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.gradient_table_head_bg {
  border-radius: 45px 0px 0px 0px;
  background: var(--hhhhh, linear-gradient(90deg, #19417a 0%, #0b9444 100%));
  box-shadow: 0px 10px 25px 0px rgba(33, 52, 48, 0.08);
}

.font_family_common {
  font-family: "Paris2024-Variable";
}

/* Default Styles (Large Screens: 1024px and above) */
.leader_boad_margin_top {
  margin-top: 13% !important;
  margin-bottom: 2% !important;
  padding:0px 4% !important;
}
/* Medium Screens (Tablets: 1220px to 1440px) */
/* @media (min-width: 2020px) and (max-width: 2560px) {
  .leader_boad_margin_top {
    margin-top: 4% !important;
  }
} */

/* Medium Screens (Tablets: 1450px to 2000px) */
/* @media (min-width: 1450px) and (max-width: 2000px) {
  .leader_boad_margin_top {
    margin-top: 4% !important;
  }
} */
/* Medium Screens (Tablets: 1220px to 1440px) */
/* @media (min-width: 1360px) and (max-width: 1440px) {
  .leader_boad_margin_top {
    margin-top: 13% !important;
  }
} */

/* Medium Screens (Tablets: 1220px to 1440px) */
/* @media (min-width: 1220px) and (max-width: 1360px) {
  .leader_boad_margin_top {
    margin-top: 20% !important;
  }
} */

/* Medium Screens (Tablets: 1025px to 1200px) */
/* @media (min-width: 1025px) and (max-width: 1200px) {
  .leader_boad_margin_top {
    margin-top: 25% !important;
  }
} */
/* Medium Screens (Tablets: 900px to 1024px) */
/* @media (min-width: 900px) and (max-width: 1024px) {
  .leader_boad_margin_top {
    margin-top: 30% !important;
  }
} */

/* Medium Screens (Tablets: 768px to 1024px) */
/* @media (max-width: 900px) and (min-width: 768px) {
  .leader_boad_margin_top {
    margin-top: 41% !important;
  }
} */

/* Small Screens (450px to 768px) */
/* @media (max-width: 766px) and (min-width: 450px) {
  .leader_boad_margin_top {
    margin-top: 75% !important;
  }
} */

/* Very Small Screens (350px to 450px) */
/* @media (max-width: 450px) and (min-width: 350px) {
  .leader_boad_margin_top {
    margin-top: 80% !important;
  }
} */

/* Extra Small Screens (Below 350px) */
/* @media (max-width: 350px) {
  .leader_boad_margin_top {
    margin-top: 120% !important;
  }
} */
@media (min-width: 320px) and (max-width: 450px) {
  .leader_boad_margin_top {
    margin-top: 50% !important;
  }
}

@media (min-width: 450px) and (max-width: 600px) {
 .leader_boad_margin_top {
    margin-top:30% !important;
  }
}

@media (max-width: 768px) {
  /* Remove dividers on smaller screens */
  .schedule-row .info-box::after {
    display: none !important;
  }
}

.disabled {
  pointer-events: none !important;
  opacity: 0.5 !important;
}

/* .react-multi-carousel-item {
  margin: 0px 14px; 
} */

@media screen and (max-width:600px){
  .search-container {
    width: auto;
}
.leader_boad_margin_top {
  margin-top: 405px!important;
  /* border:1px solid green;
  */
}
}

