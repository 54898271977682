.runningTextStates {
  margin: 0px 10px 0px 10px;
  cursor: pointer;
  padding: 1rem 1.5rem;
}

.sportPagebannerWrapper {
  background-image: url('../../assets/home2/Body/bgimg.png');
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
  height: 50vh; 
  display: flex; 
  justify-content: center; 
  align-items: center; 
  position: relative; 
}

@media (min-width: 768px) and (max-width: 1024px) {
  .sportPagebannerWrapper{
    height: 40vh;
  }
}


@media (min-width: 820px) and (max-width: 1180px) {
  .sportPagebannerWrapper{
    height: 32vh;
  }
}

@media(max-width: 690px) {
  .sportPagebannerWrapper{
    height: 88vh;
  }

}
.bannerContainer_wrapper {
  background-image: url('../../assets/home2/Body/bgimg.png');
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat;
   /* background: radial-gradient(134.45% 134.45% at 50% 50%, #9D2D20 0%, #37100B 100%); */
  height: 45vh; /* Fixed height for the container */
  display: flex; /* Enables Flexbox layout */
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  position: relative; /* Allows child elements with `position: absolute` to align relative to this container */
}

.scrollContainer {
  display: grid;
  height: 100%;
  grid-gap: 0 20px;
  grid-auto-flow: column;
  grid-auto-columns: 50%;
  overflow-x: auto;
}

.mainContainer{
  border:1px solid green;
}

.header {
  background-color: rgb(137, 104, 214);
  white-space: nowrap;
  width: 100%;
  color: white;
  display: flex;
  /* overflow-x: hidden; */
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sport_banner_wrapper{
  position: absolute;
  z-index: 10000px;
  z-index: 1;
  bottom: 0px;
  left: 0;
  right: 0;

}
.sportbannerSection {
  padding: 30px 0px 0px 0px;
}

@media (max-width: 640px) {
  .runningTextStates {
    padding: 1rem 0rem;
    font-size: 14px;
  }
}

@media (max-width: 431px) {
  .runningTextStates {
    margin: 0;
    padding: 1rem 0.3rem;
    font-size: 12px;
  }
}
  

.bannerContainer {
  position: relative;
  width: 100vw;
  margin-top: 7rem;
 
}
/* 
@media (max-width: 1023px) {
  .bannerContainer {
    margin-top: 1rem;
  }
} */


.bannerImage {
  width: 100%;
  height: auto;
}
.participantTeam {
  color: white;
  font-size: 2.5rem;
  font-weight: 700;
  font-family: Paris2024-Variable;
  text-align: center;
  line-height: 60px; /* Line height based on `isSpecialPage` logic */
   /* Keeps the width constrained as per your requirements */
}

.sportheading_name{
 
  text-align: center;
  color: #fff;
    font-family: "Paris2024-Variable";

}

/* .participantTeam {
  color: white;
  font-size: 2.5rem;
  font-weight: 700;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: Paris2024-Variable;
  line-height: 60px;
  text-align: center;
} */


/* @media only screen and (max-width:600px) {
  .participantTeam {
    top: 18% ;
    left: 8%;
    font-size: 3rem;
  }

  .bannerImage {
    height: 560px;
    width: 100%;
    object-fit: fill;
  }

  
.bannerContainer {
  margin-top:0px;
}

} */
/* Adjust banner container for mobile */

/* Target devices between 320px and 425px */
@media (min-width: 320px) and (max-width: 425px) {
  .participantTeam {
    top: 18% ;
    left: 8%;
    font-size: 1.8rem;
  }

  .bannerImage {
    height: 520px;
    width: 100%;
    object-fit: fill;
  }
  .sport_banner_wrapper{ 
    bottom: -200px !important;
    left: 0 !important;
    right: 0 !important;
    
  }
  /* .bannerContainer {
    margin-top: 1rem;
  } */
}

/* Target devices between 426px and 600px */
@media (min-width: 426px) and (max-width: 600px) {
  .participantTeam {
    top: 18% ;
    left: 8%;
    font-size: 1.8rem;
  }

  .bannerImage {
    height: 520px;
    width: 100%;
    object-fit: fill;
  }
  .sport_banner_wrapper{ 
    bottom: -200px !important;
    left: 0 !important;
    right: 0 !important;
    
  }
  .bannerContainer {
    margin-top: 1rem;
  }
}

/* Target devices between 601px and 767px */
@media (min-width: 601px) and (max-width: 767px) {
  .participantTeam {
    top: 14% ;
    left: 8%;
    font-size: 2rem;
  }

  .bannerImage {
    height: 600px;
    width: 100%;
    object-fit: fill;
  }
  .sport_banner_wrapper{ 
    bottom: -200px !important;
    left: 0 !important;
    right: 0 !important;
    
  }
  .bannerContainer {
    margin-top: 2rem;
  }
}


/* Target devices between 768px and 1023px */
@media (min-width: 768px) and (max-width: 1023px) {
  .participantTeam {
    top: 22% !important;
    left: 11% !important;
    font-size: 3rem !important;
  }
  .sport_banner_wrapper{ 
    bottom: -200px !important;
    left: 0 !important;
    right: 0 !important;
    
  }

  .bannerImage {
    height:480px;
    width: 100%;
    object-fit: fill;
  }
  .bannerContainer {
    margin-top:3rem !important;
  }
}

/* Target devices between 1023px and 1200px */
@media (min-width: 1024px) and (max-width: 1200px) {
  .participantTeam {
    top: 18% !important;
    left: 8% !important;
    font-size: 3rem !important;
  }
  .sport_banner_wrapper{ 
    bottom: -200px !important;
    left: 0 !important;
    right: 0 !important;
    
  }

  .bannerImage {
    height:560px;
    width: 100%;
    object-fit: fill;
  }
  .bannerContainer {
    margin-top:2rem !important;
  }
}

/* Target devices between 1200px and 1500px */
@media (min-width: 1200px) and (max-width: 1500px) {
  .participantTeam {
    top: 20%;
    left: 8%;
    font-size: 3rem;
  }
  .sport_banner_wrapper{ 
    bottom: -150px !important;
    left: 0;
    right: 0;
  }
  /* .bannerContainer {
    margin-top:1rem !important;
  } */
}

@media screen and (max-width:600px){
  .bannerContainer_wrapper {
    background-image: url('../../assets/home2/Body/bgmobile.png');
   height: 30vh; /* Fixed height for the container */
 }

 .bannerContainer {
  margin-top: 5rem;
 
}
.sportPagebannerWrapper{
  height: 30vh;
}

.sportbannerSection{
  background: #8A271C;
}

/* .sportheading_name{
  line-height: 130px!important;
} */
}

