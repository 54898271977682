.login-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    max-width:6rem;
    padding: 0.5rem 1.2rem 0.5rem 1.2rem;
    border-radius: 2.5rem;
    background: #fff;
    cursor: pointer;
    color:var(--heading-color);
    border: 1px solid var(--heading-color);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease; 
}


.login-btn:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.3);
  }

.register_btn{
    border: 1px solid var(--button-border-color);
    color: var(--button-maroon-text-color);
    font-family: Paris2024-Variable;
    padding: 0.6rem 1.7rem 0.6rem 1.7rem;
    border-radius: 2.5rem;
    font-weight: 600;
    background-color:var(--secondary-background-color) ;

}