



.marquee {
    --gap: 0rem;
    position: relative;
    display: flex;
    overflow: hidden;
    -webkit-user-select: none;
    user-select: none;
    gap: var(--gap);
    background:var(--background-color);
    color: white;
    padding: 8px;
    font-size: 1rem;
    font-family: "Paris2024-Variable";
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 11px;
   
  }
  
  .marquee__content {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    // gap: var(--gap);
    min-width: 100%;
    align-items: center;

  }
  .marquee_title_label{
    color:#ffff;
    text-decoration: none;
    font-weight: 700;
    
  }
  
  .marquee__content li {
    margin-right: 25px;
  }
  
  .marque-heading {
    color: #fff !important;
    text-decoration: none;
   
  }
  
  @keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - var(--gap)));
    }
  }
  
  .enable-animation .marquee__content {
    animation: scroll 45s linear infinite;
    margin: 0px;
    padding: 0;
  }
  
  .enable-animation:hover .marquee__content {
    animation-play-state: paused;
    cursor: pointer;
  }
  .dot {
    color: white;
    margin: 0px 10px 0px 7px;
  }
  
  .link {
    text-decoration: none;
    color: inherit;
  }
  @media screen and (max-width:600px){
    .marquee {
     position: fixed;
     
    }
  }