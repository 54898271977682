.social_icons_wrapper{
  display: flex;
  margin: 0;
  padding:0;

}

.social_icons{
  font-size: 1.5rem;
  list-style: none;
}

.social_icons a{
  color: #fff;
}

.social_icons a:hover{
  color: #b21f1f;
}


.uttrakhand_footer_page4 {
  background-image: 
    linear-gradient(180deg, #770408 0%, #000000 100%), /* Gradient background */
    url("../../assets/Footer2/backGround.png"); /* Image background */
  background-size: 100%; /* Ensure the image background covers the space */
  background-repeat: no-repeat; /* Prevent tiling */
  background-position: center; /* Center the image */
  color: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: space-around;
  padding: 40px 10px 30px 40px;
  // padding-top: 100px;
}

.round-corner-footer {

position: relative;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    z-index: 10;
    top: 20px;
    height: 20px;
    background-color: #fff;
}


.social_media_logos_page4 {
  display: flex;
  padding-left: 1.5rem;
  justify-content: space-between;
  width: 40%;
  gap: 10px;
  img {
    width: 20px;
    height: 20px;
  }
}
.helpline-and-logo {
  display: flex;
  flex-direction: row;
  gap: 24px;
  .helpline-text {
    width: 200px;
    height: 46px;
    flex-shrink: 0;
    color: #0c2e5e;
    border-radius: 8px;
    border-color: #0c2e5e;
    background: #fff;
    color: #0c2e5e;
    leading-trim: both;
    text-edge: cap;
    font-family: "Noto Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
}

.f-0_page4 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.f-3_page4,
.f-4_page4 {
  margin-top: 30px;
}

.footer-fields_page4 {
  color: rgba(255, 255, 255, 1);
  margin-top: 8px;
  margin-bottom: 5px;
}
.footer-fields_page4:hover{
  text-decoration: underline;
cursor: pointer;
}
.appstore_logo {
  width: 170px;
  padding-top: 20px;
}
.f-6-appstore {
 
  .googlestore_logo {
    width: 170px;
    padding-top: 20px;
  }
}

.footer-lower_page4 {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  background-color: #020202;
  color: white;
  display: flex;
  // grid-template-columns: 40% 30% 20% 20%;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem;
  .footer-left {
    padding-left: 7%;
  }
  .footer-right {
    display: flex;
    align-items: center;
    justify-content: Aspace-around;
  }
}
@media (max-width: 770px) {
  .footer-lower_page4 {
    display: none;
    width: 100%;
  }
}

.fontstyle {
  // color: #fff;
  font-family: Paris2024-Variable;
  font-weight: 550;
  text-transform: uppercase;
  padding-left: 1.5rem;
}

.rasteriya_hindi_font {
  font-size: 2rem;
}

.chatbot-section {
  // display: flex;
  // justify-content: space-between;
  // padding: 1rem 2rem 0rem 2rem;
  // position: relative;
  // bottom: 0;
  // background-color: transparent;
  // z-index: 9000;
  // width: 100%;

  width: 100%;
  padding: 1rem 2rem 0rem 2rem;
  display: flex;
  justify-content: space-between;
  z-index: 9000;
}

.chatbot-fixed {
  position: fixed;
  width: 100%;
  bottom: 10px;
  left: 0;
  right: 0;
}

.chatbot-above-footer {
  position: relative;
  background-color: black;
  width: 100%;
  bottom: auto;
  right: auto;
}

.helpline-text {
  width: 12.75625rem;
  height: 2.5rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  background: #fff;
  border: none;
  margin-left: 1rem;
  text-transform: uppercase;
  color: #0c2e5e;
  font-family: "Noto Sans";
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.footer_open_close {
  display: flex;
  justify-content: space-between;
  span {
    font-size: 32px;
    display: none;
  }
}

.thirty-eight-text-footer {
  color: #fff;
  width: 10rem;
  height: 10.9375rem;
  font-family: Lato;
  font-size: 8.875rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  // padding: 0rem 0rem 0rem 8rem;
  position: relative;
  .h-over-sub {
    position: absolute;
    top: 32px;
    right: -50px;
    // width: 5.4375rem;
    height: 2.9375rem;
    padding-left: 0rem;
    font-size: 3.5rem;
  }
}

.uk_changed_img_footer {
  position: absolute;
  top: -45px;
  right: -10px;
}
.uk_lower_img_footer {
  position: absolute;
  top: 10px;
  right: -10px;
}
.uk_changed_img_footer img {
  width: 40px;
  height: 40px;
}

.uk_lower_img_footer img {
  width: 40px;
  height: 40px;
}

@media (max-width: 1100px) {
  .uttrakhand_footer_page4 {
    flex-direction: column;
    gap: 1rem;
  }
  .social_media_logos_page4 {
    padding-left: 2rem;
    gap: 8px;
  }
  .chatbot-section {
    max-width: 100%;
  }
  .footer-right {
    flex-direction: column;
  }
  .footer_open_close {
    span {
      display: block;
    }
  }
}

@media (max-width: 770px) {
  .chatbot-section {
    max-width: 100%;
  }
  .footer-bottom {
    // border: 10px solid red;
    // display: none;
  }
  .thirty_eight_cont_footer {
    width: 250px;
    left: 100px;
  }
}

.thirty_eight_cont_footer {
  position: relative;
  left: 50px;
}

.footer-th {
  position: absolute;
  top: -60px;
  font-family: Lato;
  font-size: 50px;
  font-weight: 900;
  color: transparent; /* Makes the text itself hollow */
  -webkit-text-stroke: 2px var(--heading-color); /* Thicker stroke width for more hollow space */
  text-shadow: 1px 1px 0px ,
    /* Multiple, smaller shadows for a stronger outline effect */ -1px -1px 0px
      transparent,
    2px 2px 0px transparent, -2px -2px 0px transparent, 3px 3px 0px transparent,
    -3px -3px 0px transparent;
}


.footer-lower_page4 {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  text-transform:capitalize;
  background-color: #020202;
  color: white;
  display: flex;
  // grid-template-columns: 40% 30% 20% 20%;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem;
  .footer-left {
    padding-left: 7%;
  }
  .footer-right {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
.ng_title_footer {
  font-family: "Paris2024-Variable";
  font-size: 36px;
  font-weight: 700;
  line-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  font-size: 25px;
  cursor: pointer;
}


.ukgoc-detail {
  margin: 5px 0px 4px 0px;
}





