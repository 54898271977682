body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "buffalo-r", "DancingScript", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* html, body {
  margin: 0 !important;
  padding: 0 !important;
  overflow-x: hidden !important;
} */

@font-face {
  font-family: "buffalo-r";
  src: url("./assets/fonts/buffalo.ttf");
}

@font-face {
  font-family: "DancingScript";
  src: url("./assets/fonts/DancingScript-VariableFont_wght.ttf");
}
body.modal-open {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#runningTextBar {
  font-family: "Lato", sans-serif;
}

html {
  scroll-padding-top: 100px;
}

.centered-flex-element {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/* .custom-heading-color {
  color: #2b1c12;
  font-weight: bold;
} */

.custom-table-header-background-color {
  background-image: linear-gradient(to right, #726d6d, #535050);
  color: white;
}

.rounded-top-left-md {
  border-top-left-radius: 40px;
}

.bg-gold-medal {
  background-color: #efc025;
}

.bg-silver-medal {
  background-color: #c4c4c4;
}

.bg-bronze-medal {
  background-color: #af820a;
}

.custom-pink-background-gradient {
  background-image: linear-gradient(to right, #8901ed, #b80693);
}

.gradient-text {
  color: transparent;
  background-image: linear-gradient(to right, #8901ed, #b80693);
  -webkit-background-clip: text;
  background-clip: text;
}

.cursor-pointer {
  cursor: pointer !important;
}

.italics {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

.flex-column {
  flex-direction: column;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.place-items-center {
  place-items: center !important;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.w-75 {
  width: 75%;
}

.h-full {
  height: 100%;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-11 {
  display: -webkit-box;
  -webkit-line-clamp: 11;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Col Span Styles */
.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}


.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-7 {
  grid-column: span 7 / span 7;
}

.col-span-8 {
  grid-column: span 8 / span 8;
}

.col-span-9 {
  grid-column: span 9 / span 9;
}

.col-span-10 {
  grid-column: span 10 / span 10;
}

.col-span-11 {
  grid-column: span 11 / span 11;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.color-light-gray {
  color: #8b8b8b;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.selected-tab-aboutus {
  color: red;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.commonBtnPinkGradient {
  /* background: linear-gradient(to right, #aa27d1, #7121d4); */
  background: var(--hhhhh, linear-gradient(90deg, var(--heading-color) 0%, #0B9444 100%));
  color: white;
  font-weight: bold;
  border-radius: 20px;
  outline: none;
  border: none;
  padding: 7px 30px;
  cursor: pointer;
  width: 10rem;
}




.crouselArrowStyle {
  border-radius: 100%;
  padding: 0.45rem 1rem;
  height: 2.7rem;
  background: #8E58E4;
  width: fit-content;
  color: white;
  font-weight: bold;
}

.header_active_menu{
  /* color:#0B9444!important; */

  background:var(--background-color);
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: transparent;
}
.runningTextColor {
  background-color: #7c59bf;
}

.runningTexHoverColor:hover {
  background-color: #7c59bf;
}

.line-height-2 {
  line-height: 1.5rem;
}

.hidden {
  display: none;
}

.no-scrollbar {
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.no-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.participantTeam {
  color: white;
  font-size: 4rem;
  font-weight: bold;
  position: absolute;
  /* top: 8rem;
  left: 17%; */
  bottom: 10rem;
  top: 6rem;
  /* left: 10%; */
  /* border:1px solid red; */
}

.space-y-4 > * + * {
  margin-top: 1.5rem;
}

.space-y-8 > * + * {
  margin-top: 8rem;
}

.gray-c2 {
  color: #2b2b2b;
}

.bg-gray-c1 {
  background-color: #f8f8f8;
}

button:disabled {
  opacity: 0.4;
}

/* @media (max-width: 1024px) {
  .participantTeam {
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  .participantTeam {
    font-size: 2rem;
    top: 3rem;
    left: 27%;
    top: 2rem;
  }
}

@media (max-width: 548px) {
  .participantTeam {
    left: 17%;
    top: 2rem;
    font-size: 1.5rem;
  }
}

@media (max-width: 425px) {
  .participantTeam {
    top: 1.2rem;
    left: 30%;
    font-size: 1.2rem;
  }
}
@media (max-width: 394px) {
  .participantTeam {
    font-size: 1rem;
    top: 2.2rem;
  }
} */

@media only screen and (max-width:600px) {
  .participantTeam {
   top:31px;
  
  }
}

.color-orange{
  color:var(--heading-color) !important;
}

.font-lato{
  font-family: Lato !important;

}