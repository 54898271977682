.custom-list {
  text-align: right;
  list-style: none;
  padding-left: 0;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  cursor: pointer;
}
ul.li {
  line-height: 30px;
}
.footer-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  width: 100%;
}
.chat-bot-mauli {
  position: fixed;
  bottom: 90px;
  z-index: 1001;
  right: 10px;
}

.toggle-button-footer {
  background-color: var(--heading-color);
  /* width: 40px; */
  border-radius: 10px;
  border: none;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  transform: translateX(-10%);
  position: fixed;
  bottom: 50px;
  z-index: 1001;
  right: 10px;
}

@media (max-width: 660px) {
  .chat-bot-mauli {
    bottom: 0px;
  }
}

.footer-img {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.brand {
  transform: translateX(-50%);
  font-size: 12px;
  color: white;
  background: #191970;
  padding: 10px 20px;
  border-radius: 20px;
  margin-bottom: -7px !important;
  cursor: pointer;
}

.brand .before,
.brand .after {
  border: 0 solid transparent;
  box-sizing: border-box;
  pointer-events: none;
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  right: 0;
  border-radius: 10px;
  transition: border-color 0s, width 0.25s, height 0.25s;
}

.brand .before {
  border-bottom-width: 4px;
  border-left-width: 4px;
}

.brand .after {
  border-top-width: 4px;
  border-right-width: 4px;
}

.brand:hover {
  animation: pulse 1s;
  box-shadow: 0 0 10px 5px #fff;
  background-image: linear-gradient(to right, #8901ed, #b80693);
  /* border:1px solid #fff; */
}

.brand:hover .before,
.brand:hover .after {
  border-color: #8e58e4;
  transition: border-color 0s, width 0.25s, height 0.25s;
  width: 100%;
  height: 100%;
}

.chatbot-img {
  width: 65px;
  height: 65px;
  /* border: 2px solid white; */
  border-radius: 50%;
  /* background-color: "grey"; */
  background-color: #fff;
  border: 2px solid #b80693 !important;
}

/* .chatbot-img:hover {
  background-color: #fff;
  border: 3px solid #b80693;
} */

.email-body {
  display: flex;
  gap: 5px;
  font-size: 18px;
  font-weight: 600;
}

.emergency-contact {
  background-image: linear-gradient(to right, #8901ed, #b80693);
  color: white;
  font-weight: bold;
  font-size: 1.125rem;
  border-radius: 20px;
  outline: none;
  border: none;
  padding: 15px 30px;
  cursor: pointer;
}

.emergency-contact:hover {
  background-image: linear-gradient(to right, #b80693, #8901ed);
  box-shadow: 0 0 10px 5px #fff;
}
.footer-copyright {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  text-align: center;
}
.footer-bottom-visible {
  display: none;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  margin-top: 20px;
  padding: 20px 0px;
}
.footer-bottom-visible .custom-list {
  margin-top: 0px;
}
.footer-bottom-visible li {
  list-style-type: disc;
  color: white;
  list-style-position: inside;
}
.footer-bottom-converstaion-visible {
  display: none !important;
}
@media (min-width: 1300px) {
  .row-custom-margin {
    margin-left: 200px;
    margin-right: 200px;
    align-items: end;
  }
}
.footer-main-container {
  padding-bottom: 85px !important;
}
.icon-img {
  width: 20px;
  height: 20px;
  margin-right: 20px;
  margin-left: 0;
}
.custom-card-text {
  font-size: smaller;
}
.custom-patner {
  width: 45vw;
  margin: auto;
}
.sponserlogo {
  width: 45%;
}

@media (max-width: 786px) {
  .sponserlogo {
    width: 60%;
  }
  .custom-patner {
    width: 60vw;
  }
  /* .footer-container {
    padding: 30px;
  } */
  .custom-list {
    text-align: left;
    margin-top: 40px;
    margin-bottom: 0.25rem;
  }
}

/* Footer container styles */
.footer-container {
  position: relative;
  background-image: url(../../assets/svg/footer/Footerbottombar.jpg);
  width: 100%;
  height: 80px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Chatbox styles */
.chat-box {
  position: relative;
}
.chat-box img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 3px solid #fff;
  object-fit: contain;
}
.chat-box::after {
  content: "Need Help? Ask Mauli";
  position: absolute;
  bottom: calc(100% + 10px);
  right: 0px;
  padding: 5px 20px 8px;
  border-radius: 30px;
  background-color: var(--heading-color);
  color: #ffffff;
  font-weight: 600;
  width: max-content;
  animation: bounce-chat-box 2.5s both infinite;
  /* box-shadow: 0 0 10px 5px #087cdf; */
}
/* .chat-box:hover::after {
  box-shadow: 0 0 10px 5px #b80693;
} */

@keyframes bounce-chat-box {
  0%,
  100%,
  20%,
  50%,
  80% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

/* Time text styles */
.time-text {
  font-size: 40px;
  color: #fff !important;
  background-color: #8968d6 !important;
}

/* Footer background image */
.footer-background {
  width: 100%;
  height: 70px;
}

@media (max-width: 576px) {
  .footer-bottom-converstaion-hide {
    display: none !important;
  }
  .footer-bottom-converstaion-visible {
    display: block !important;
  }
  .footer-bottom-hide {
    display: none;
  }
  .custom-patner {
    width: 60vw;
  }
  .footer-bottom-visible {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .sponserlogo {
    margin-top: 30px;
    width: 100%;
  }
  .footer-container {
    justify-content: center;
    align-items: center;
    height: 65px;
  }
  .brand {
    transform: none;
    width: fit-content;
  }
  .email-body {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-top: 10px;
  }
  .time-text {
    font-size: 20px !important;
  }

  .chat-box img {
    width: 50px !important;
    height: 50px !important;
  }
}
