.live-timing {
  display: flex;
  // justify-content: space-between;
  gap: 3rem;
  // padding: 1rem 2rem 1rem 2rem;
  width: 100%;
  
}
.num-live-time {
  color: var(--white-text-color);
  font-family: "Paris2024-Variable";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.colon {
  display: inline-block;
  font-size: 36px;
  line-height: 1;
  vertical-align: middle;
  position: absolute;
  /* right: -4px; */
  left: 125px;

}
.time-container {
  text-align: center;
  width: 100%;
}
.days,
.hours,
.mins,
.sec {
  font-size: smaller;
  color: var(--white-text-color);
  font-size: 1rem;
}
.num-live-time {
  text-align: center;
}
.clock-dot {
  padding: 0rem 0rem 0rem 1.5rem;
}
@media (max-width: 768px) {
  .live-timing {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding: 1rem 1rem 1rem 1rem;
  }
  .num-live-time {
    font-size: 1.8rem;
  }
}

@media screen and (max-width:600px){
  .colon {
    left: 75px;
  }
}
