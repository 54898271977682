.page {
  text-align: center;
  padding: 0px;
  margin: 0px;
}

.dateContainer {
  display: flex;
  gap: 4px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  justify-content: space-between;
  padding-top: 5px;
  width: 100%;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.dateCard {
  background-color: rgba(255, 255, 255, 0.2);
  color: var(--heading-color);
  border: 1px solid var(--heading-color);
  text-align: center;
  padding: 3px 16px 2px 16px;
 
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1); */
}
.dayCalender {
 
  background-color: rgb(116, 29, 29);
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.arrowIcon {
  cursor: pointer;
  font-size: 24px;
  transition: transform 0.3s ease-in-out;
  color: var(--heading-color);
}

.arrowIcon:hover {
  transform: scale(1.2);
}

.arrowContainer {
  display: flex;
  align-items: center;
}

.photo {
  position: absolute;
  background-image: url("Image 54.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  height: 330px;
}

.schedule_navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  /* top: -75px; */
  /* left: 50%;  */
  /* transform: translateX(-50%);  */
  /* align-items: center; */
  /* border-radius: 25px; */
  /* background: #FFF;
  box-shadow: 2px 8px 20px 0px rgba(0, 0, 0, 0.10); */
  padding: 20px 40px;
  z-index: 1;
  width: 100%;

}

.photogalleryContainer {
  /* position: absolute;
  top: 25px; */
  align-items: center;
  border-radius: 25px;
  padding: 10px 30px;
  z-index: 1;
  width: 100%;
  
}
@media (max-width: 599px) {
  .schedule_navbar {
    width: 95%;
    left: 52.2%;
    /* border-radius: 30px; */
    /* border:1px solid #BFBFBF */
  }

  /* .photogalleryContainer {
  top: 80px;
  } */
  
}

@media(min-width: 600px) and (max-width: 991px) {
  .schedule_navbar {
    width: 96%;
    left: 51.7%;
    /* border-radius: 30px; */
    /* border:1px solid #BFBFBF; */
    padding: 20px 5px;
  }
  

}

@media(min-width: 600px) and (max-width: 991px) {
  .schedule_navbar {
    padding: 20px 5px;
  }
}
.cities {
  color: #212121;

  font-family: Lato;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

/* .cities:hover {
  transition: all 0.5s ease-in-out;
  color: #fff;
  font-size: 26px;
  background-color: #5b5a5d63;
} */

.city_div {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: -90px;
  height: 200px;
  color: #bc068f;
  /* font-family: Poppins; */
  font-size: 60px;
  font-weight: normal;
}

.venues {
  background: #f5f5f5;
  padding: 2px;
  margin: 0 89px 0px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.icon {
  width: 55px;
  height: 55px;
  padding: 10px;
  border-radius: 10px;
  background: linear-gradient(to right, var(--heading-color), var(--heading-color));
  /* background-image: linear-gradient(to right, #b4069b, #9001df); */
}

.containerr {
  overflow: hidden;
  box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.02);
  /* font-family: Lato; */
  background: #fff;
  margin-top: 3px;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 4.5rem;
  border-radius: 10px;
}
.containerr2 {
  display: none;
}
.img {
  display: flex;
  justify-content: center;
  align-items: center;

  flex: 0.08;
  height: 4.5rem;
}

.game {
  display: flex;
  align-items: center;
  flex: 0.2;
  height: 4.5rem;
}

.cluster {
  display: flex;
  align-items: center;
  flex: 0.12;
  height: 4.5rem;
}

.ground {
  display: flex;
  align-items: center;
  flex: 0.36;
  height: 4.5rem;
}

.sdate {
  display: flex;

  align-items: center;
  flex: 0.12;

  height: 4.5rem;
}

.edate {
  display: flex;

  align-items: center;
  flex: 0.12;
  height: 4.5rem;
}

.schedule-header {
  align-items: center;
  height: 4rem;
  background: #8E58E4;
  color: #fff;
  font-weight: 700;
  border-radius: 15px 0px 0px 0px;
}
.containerrow {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow-x: hidden;
}
.separator {
  height: 80%;
  width: 2px;
  margin: 0px 5px 0px 5px;
  background-color: #bc068f;
}
.rightSeparator {
  display: flex;
  justify-content: center;
  align-items: center;
}
.rightBlock {
  height: 40px;
  width: 10px;
  background-color: #bc068f;
}
.gridOverview {
  top: 0.3%;
  position: sticky;
  /* background: linear-gradient(90deg, var(--heading-color), #a52a2a); */
  background: #fff;
  color:#fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 20px;
  /* background: radial-gradient(134.45% 134.45% at 50% 50%, #9D2D20 0%, #37100B 100%); */
  z-index: 999;
}

.dateTableView{
  margin: 5px 48px 48px 48px !important;
}

@media (min-width: 769px) and (max-width: 991px) {
  .navbar {
    height: 3.5rem;
  }

  .cities {
    padding: 5px 0 5px 0;
    font-size: 24px;
  }

  .venues {
    padding: 1px;
    margin: 0 59px 0px;
  }

  .icon {
    width: 45px;
    height: 45px;
    padding: 7px;
    border-radius: 8px;
  }

  .city_div {
    margin-top: 20px;
    margin-bottom: -90px;
    height: 180px;
    font-size: 50px;
  }

  .containerr {
    font-size: 15px;
  }
}

@media (min-width: 575px) and (max-width: 768px) {
  .navbar {
    height: 3rem;
    padding: 0px;
  }

  .cities {
    padding: 3px 0 3px 0;
    font-size: 0.9rem;
    margin-top: 10px;
  }

  .venues {
    padding: 1px;
    margin: 0 40px 0px;
  }

  .icon {
    width: 40px;
    height: 40px;
    padding: 6px;
    border-radius: 6px;
  }

  .city_div {
    height: 150px;
    font-size: 45px;
  }

  .containerr {
    font-size: 12px;
  }
  /* .dateTableView {
    margin: 5px 20px 20px 20px !important;
    max-height: calc(100vh - 180px);
  } */
}
/* .dateTableView {
  border:1px solid red !important;
}; */
@media (min-width: 320px) and (max-width: 425px) {
  .dateTableView {
    overflow: auto !important;
    margin: 0px 20px 20px 20px !important;
    max-height: calc(200vh - 250px) !important;
    /* border:1px solid red !important; */
  }
}
@media (min-width: 425px) and (max-width: 575px) {
  .dateTableView {
    overflow: auto !important;
    margin: 0px 20px 20px 20px !important;
    max-height: calc(200vh - 250px) !important;
    /* border:1px solid red !important; */
  }
}
@media (min-width: 575px) and (max-width: 660px) {
  .dateTableView {
    overflow: auto !important;
    margin: 0px 20px 20px 20px !important;
    max-height: calc(200vh - 250px) !important;
    /* border:1px solid red !important; */
  }
}


@media (max-width: 576px) {
  /* .dateTableView {
    overflow: auto !important;
    margin: 160px 20px 20px 20px !important;
    max-height: calc(200vh - 180px) !important;
  } */
  /* .dateTableView > * {
    min-width: 576px;
  } */
  .header {
    display: none;
  }
  .containerr {
    display: none;
  }
  .containerr2 {
    display: grid;
    grid-template-columns: 50px 1fr 25px;
    gap: 10px;
    margin-bottom: 10px;
    margin: 10px 2px 20px 2px;
    border: 1px solid white;
    border-radius: 5px;
    background: white;
  }
  .ground {
    color: #747474;
  }
  .cluster {
    color: #747474;
  }
  .venues {
    margin: 0px;
  }
  .dateContainer > div {
    transform: scale(0.75);
  }
  .leftText {
    transform: scale(0.8);
    flex-direction: column;
  }
 
}

.overiew_margin_top {
  margin-top: 12.1rem;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-bottom: 40px;
  border-radius: 5px;
}

@media screen and (max-width:600px){
  .photogalleryContainer {
    padding: 10px 0px;
  }
}
@media (min-width: 550px) and (max-width: 660px) {
  .gridOverview {
    top: 0%;
  }
}
@media (min-width: 661px) and (max-width: 760px) {
  .gridOverview {
    top: 8.4%;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .gridOverview {
    top: 11%;
  }
}

@media (min-width: 1440px) and (max-width: 1623px) {
  .gridOverview {
    top: 14.5%;
  }
}
@media (min-width: 1624px) and (max-width: 1920px) {
  .gridOverview {
    top: 10%;
  }
}

@media (min-width: 1921px) and (max-width: 2560px) {
  .gridOverview {
    top: 7%;
  }
}